/* @import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-SemiBold.woff");
@import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-Regular.woff");
@import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-Medium.woff"); */

.coin-search-container {
  width: 20.94rem;
  position: relative;
  padding: 0% 4% 4% 4%;
}

.coin-search-container h2,
h3 {
  font-size: 1rem;
  margin: 0rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: white;
  text-align: left;
}
.coin-search-container p {
  font-size: 0.75rem;
  margin: 0rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
}

.fear-and-greed-container p {
  font-size: 0.75rem;
}
.fear-and-greed-chart {
  background-color: #171717;
}
