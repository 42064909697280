* {
  box-sizing: border-box;
  /* border: 1px solid white; */
}

#root {
  min-height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.App {
  /* position: relative; */
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  /* font-family: Gilroy-Bold; */
  color: white;
  max-width: 23.44rem;

  /* flex-basis: 100%; */
  /* align-items: center; */
}
