/* @font-face {
  font-family: Gilroy-Bold;
  src: url(../../assets/fonts/Gilroy-Bold.woff);
} */
.header-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 8% 4% 4% 4%;
}
.header {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  text-align: left;
  /* display: flex; */
  /* flex-direction: column; */
  background-color: #171717;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: white;

  /* border: 1px solid white; */
}

h1 {
  margin: 0;
  font-size: 1.1rem;
}

.welcome-message {
  font-weight: 400;
}
