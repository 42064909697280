/* @import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-SemiBold.woff");
@import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-Regular.woff"); */

.weather-container {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 1fr 5fr;
  border-radius: 0.31rem;

  max-height: 10rem;
  max-width: 20.94rem;
  padding: 0% 4% 4% 4%;
  color: white;
}

.weather-header {
  grid-column: 1;
}

.weather-form {
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.input-box,
textarea {
  background: cyan;
}

.weather-container h2 {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  /* padding-left: 1.688rem; */
  text-align: left;
  color: white;
  margin: 0rem;
}
.weather-container p {
  font-size: 0.75rem;
  margin: 0rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
}

.weather-city p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 0.9rem;
}

.search-form {
  display: flex;
}

.weather-search-results {
  background-color: #171717;
  /* background-color: orange; */
  grid-row: 2;
  grid-column-start: -3;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
