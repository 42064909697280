/* @import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-SemiBold.woff");
@import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-Regular.woff");
@import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-Medium.woff"); */

.fear-and-greed-results h2 h3 {
  font-size: 1rem;
  margin: 0rem;
}

.fear-and-greed-chart {
  background-color: #171717;
}

.fear-and-greed-classification,
.fear-and-greed-value-classification {
  margin: 0rem;
  font-size: 1.1rem;
}

.fear-and-greed-classification p {
  margin: 0rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
