/* @import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-SemiBold.woff");
@import url("https://github.com/CrowdedAstronaut/daily-hud/blob/main/src/assets/fonts/Gilroy-Regular.woff"); */

.fear-and-greed-container {
  /* height: 30vh; */
  /* border: 5px solid white; */

  max-width: 20.94rem;
  padding: 0% 4% 4% 4%;
  border-radius: 0.31rem;
  color: white;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* margin: 0.3rem; */
  /* border: 1px solid white; */
}

.fear-and-greed-container h2,
h3 {
  margin: 0rem;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  /* padding-left: 1.688rem; */
  text-align: left;
}

#gauge-chart2 {
  max-width: 20rem;
}
