.bitcoin-container {
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 4.188rem;
  background-color: #171717;
  margin-bottom: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
}

.ethereum-container {
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 4.188rem;
  background-color: #171717;
  margin-bottom: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
}

.cardano-container {
  grid-row: 3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 4.188rem;
  background-color: #171717;
  margin-bottom: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
}

.bitcoin-icon-container,
.ethereum-icon-container,
.cardano-icon-container,
.bitcoin-mobile-chart,
.ethereum-mobile-chart,
.cardano-mobile-chart {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bitcoin-price-info,
.ethereum-price-info,
.cardano-price-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.bitcoin-mobile-chart {
  grid-column: 2;
}
